import React, { useState } from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import LogoutButton from './LogoutButton';
import HistoryButton from './HistoryButton';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
const Header = ({ currentPage, setCurrentPage, academyTab, setAcademyTab }) => {
  const { user, loading } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleChange = (event, newContent) => {
    if (newContent !== null) {
      setCurrentPage(newContent);
    }
  };

  const handleAcademyChange = (event, newContent) => {
    if (newContent !== null) {
      setAcademyTab(newContent);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleAcademy = () => {
    if (location.pathname === '/academy') {
      navigate('/admin');
    } else{
      navigate('/academy');
    }
  };

  const handleSettings = () => {
    if (location.pathname === '/settings') {
      navigate(-1);
    } else{
      navigate('/settings');
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const academyIcon = location.pathname === '/academy' ? <DescriptionIcon style={{ color: 'white' }} /> : <SchoolIcon style={{ color: 'white' }} />;
  const settingsIcon =  <SettingsIcon style={{ color: 'white' }} />;

  return (
    <AppBar position="static">
      <Toolbar sx={{ minHeight: 64 }} >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {user ? `Welcome, ${user.username}` : 'Welcome'}
        </Typography>
        {loading ? (
          <>
            <Skeleton variant="text" width={100} height={32} sx={{ marginRight: 2 }} />
            <Skeleton variant="text" width={100} height={32} sx={{ marginRight: 2 }} />
            <Skeleton variant="rectangular" width={100} height={32} />
          </>
        ) : user ? (
          <>
            
            {user.team === 'admin' && (
              <>
                  {location.pathname === '/admin' && (
                    <>
                    <ToggleButtonGroup
                        value={currentPage}
                        exclusive
                        onChange={handleChange}
                        aria-label="Content Toggle"
                        sx={{ px: 2, display: { xs: 'none', md: 'block' } }}
                      >
                      <ToggleButton value="MasterSheet" className={ 'main-toogle-button' }>MasterSheet</ToggleButton>
                      <ToggleButton value="NCAccounts" className={ 'main-toogle-button' }>NC Accounts</ToggleButton>
                      <ToggleButton value="Domains" className={ 'main-toogle-button' }>Domains</ToggleButton>
                      <ToggleButton value="Parent" className={ 'main-toogle-button' }>Parent</ToggleButton>
                      <ToggleButton value="Users" className={ 'main-toogle-button' }>Users</ToggleButton>
                    </ToggleButtonGroup>
                    </>
                  )}
                  {location.pathname === '/academy' && (
                    <>
                    <ToggleButtonGroup
                        value={academyTab}
                        exclusive
                        onChange={handleAcademyChange}
                        aria-label="Content Toggle"
                        sx={{ px: 2, display: { xs: 'none', md: 'block' } }}
                      >
                      <ToggleButton value="exam_domains" className={ 'main-toogle-button' }>Exam Domains</ToggleButton>
                      <ToggleButton value="all_domains" className={ 'main-toogle-button' }>All Domains</ToggleButton>
                      <ToggleButton value="tasks" className={ 'main-toogle-button' }>Tasks</ToggleButton>
                      {user.team === 'admin' && (
                        <ToggleButton value="ht" className={ 'main-toogle-button' }>HT</ToggleButton>
                      )}
                    </ToggleButtonGroup>
                    </>
                  )}
                  </>
                )}
                {user.team === 'intern' && (
              <>
                  {location.pathname === '/academy' && (
                    <>
                    <ToggleButtonGroup
                        value={academyTab}
                        exclusive
                        onChange={handleAcademyChange}
                        aria-label="Content Toggle"
                        sx={{ px: 2, display: { xs: 'none', md: 'block' } }}
                      >
                      <ToggleButton value="exam_domains" className={ 'main-toogle-button' }>Exam Domains</ToggleButton>
                      <ToggleButton value="all_domains" className={ 'main-toogle-button' }>All Domains</ToggleButton>
                      <ToggleButton value="tasks" className={ 'main-toogle-button' }>Tasks</ToggleButton>
                    </ToggleButtonGroup>
                    </>
                  )}
                  </>
                )}
                <HistoryButton/>
                {user.team === 'admin' && (
                <>
                  <IconButton
                    color="primary"
                    className='main-toggle-button'
                    onClick={handleAcademy}
                    sx={{display: { xs: 'none', md: 'block' }}}
                  >
                    {academyIcon}
                  </IconButton>
                </>
                )}
            <Typography variant="body1" sx={{ marginRight: 2, display: { xs: 'none', md: 'block' } }}>
              Team: {user.team}
            </Typography>
            <Typography variant="body1" sx={{ marginRight: 2, display: { xs: 'none', md: 'block' } }}>
              Group: {user.usergroup}
            </Typography>
            {user.team === 'admin' && (
                <>
                  <IconButton
                    color="primary"
                    className='main-toggle-button'
                    onClick={handleSettings}
                    sx={{display: { xs: 'none', md: 'block' }}}
                  >
                    {settingsIcon}
                  </IconButton>
                </>
                )}
            <LogoutButton />
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            {/* Drawer для мобильного вида */}
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                {user.team === 'admin' && location.pathname === '/admin' && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleChange(null, 'MasterSheet')}>
                        <ListItemText primary="MasterSheet" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleChange(null, 'NCAccounts')}>
                        <ListItemText primary="NC Accounts" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleChange(null, 'Domains')}>
                        <ListItemText primary="Domains" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleChange(null, 'Parent')}>
                        <ListItemText primary="Parent" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleChange(null, 'Users')}>
                        <ListItemText primary="Users" />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
                {user.team === 'admin' && location.pathname === '/academy' && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleAcademyChange(null, 'exam_domains')}>
                        <ListItemText primary="Exam Domains" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleAcademyChange(null, 'all_domains')}>
                        <ListItemText primary="All Domains" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleAcademyChange(null, 'tasks')}>
                        <ListItemText primary="Tasks" />
                      </ListItemButton>
                    </ListItem>
                    {user.team === 'admin' && (
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => handleAcademyChange(null, 'ht')}>
                          <ListItemText primary="HT" />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </>
                )}
                <ListItem disablePadding>
                  <ListItemButton onClick={handleAcademy}>
                    <ListItemText primary="Academy" />
                    {academyIcon}
                  </ListItemButton>
                </ListItem>
                {user.team === 'admin' && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleSettings}>
                      <ListItemText primary="Settings" />
                      {settingsIcon}
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding>
                  <ListItemButton>
                    <LogoutButton />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
