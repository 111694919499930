import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoryData } from '../actions/historyActions';
import { useAuth } from '../contexts/AuthContext';

const HistoryTable = () => {
  const [open, setOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState({});
  const { user } = useAuth();

  const dispatch = useDispatch();
  const history = useSelector(state => state.history.historyData);
  //const loading = useSelector(state => state.history.loading);
  //const error = useSelector(state => state.history.error);

  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleAccordion = (rowId) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchHistoryData(user.team));
    }
  }, [user, dispatch]);

  return (
    <>
      <Button sx={{ mx: 2 }} variant="contained" color="primary" onClick={handleOpen}>
        Show History
      </Button>
      <div style={{}}>
        <TableContainer
          component={Paper}
          style={{
            position: 'fixed',
            top: '64px',
            right: open ? 0 : '-1000px',
            width: '1000px',
            zIndex: '3',
            transition: 'right 0.3s ease',
            maxHeight: 'calc(100vh - 64px)',
          }}
        >
          <Table stickyHeader className={'table-history'}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: 1 }}>Date</TableCell>
                <TableCell sx={{ p: 1 }}>Action</TableCell>
                <TableCell sx={{ p: 1 }}>Domain</TableCell>
                <TableCell sx={{ p: 1 }}>User</TableCell>
                <TableCell sx={{ p: 1 }}>Column</TableCell>
                <TableCell sx={{ p: 1 }}>From</TableCell>
                <TableCell sx={{ p: 1 }}>To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ p: 1 }}>
                    {new Date(row.date).toISOString().replace('T', ' ').slice(0, 19)}
                  </TableCell>
                  <TableCell sx={{ p: 1 }}>{row.action}</TableCell>
                  <TableCell sx={{ p: 1 }}>{row.domain}</TableCell>
                  <TableCell sx={{ p: 1 }}>{row.user}</TableCell>
                  <TableCell sx={{ p: 1 }}>{row.columnName}</TableCell>
                  <TableCell sx={{ p: 1 }}>
                    {row.action === 'Remove' ? (
                      <div>
                        <Button onClick={() => toggleAccordion(row.id)} variant="contained" color="primary">
                          Info
                        </Button>
                        {accordionOpen[row.id] && (
                          <div>
                            {Object.entries(JSON.parse(row.previousValue)).map(([key, value]) => (
                              <div key={key}>
                                <strong>{key}:</strong> {value}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      row.previousValue
                    )}
                  </TableCell>
                  <TableCell sx={{ p: 1 }}>{row.nextValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default HistoryTable;
