// settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  global: {
    teamsList: [],
    groupsList: [],
    parentsList: [],
  },
  academy: {
    tabNames: [],
    tabActivation: [],
    esp: '',
    mailingType: '',
    footer: '',
    broadcast_days: '',
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTeamsList(state, action) {
      state.global.teamsList = action.payload;
    },
    setGroupsList(state, action) {
      state.global.groupsList = action.payload;
    },
    setParentsList(state, action) {
      state.global.parentsList = action.payload;
    },
    setTabNames(state, action) {
      state.academy.tabNames = action.payload;
    },
    setTabActivation(state, action) {
      state.academy.tabActivation = action.payload;
    },
    academySetEsp(state, action) {
      state.academy.esp = action.payload;
    },
    setAcademyMailingType(state, action) {
      state.academy.mailingType = action.payload;
    },
    setAcademyFooter(state, action) {
      state.academy.footer = action.payload;
    },
    setAcademyBroadcastDays(state, action) {
      state.academy.broadcast_days = action.payload;
    },
  },
});

export const {
  setTeamsList,
  setGroupsList,
  setParentsList,
  setTabNames,
  setTabActivation,
  academySetEsp,
  setAcademyMailingType,
  setAcademyFooter,
  setAcademyBroadcastDays
} = settingsSlice.actions;

export default settingsSlice.reducer;
