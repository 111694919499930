import React, { useState, useEffect } from 'react';
import ExamDomains from '../components/academy/tables/Exam';
//import ExamDomains from '../components/academy/tables/ExamDomains(virtual)';
import AllDomains from '../components/academy/tables/AllDomains';
import Tasks from '../components/academy/tables/Tasks';
import HT from '../components/academy/HT';
import { useAuth } from '../contexts/AuthContext';
import {
  TextField,
} from '@mui/material';
import { getTeamsList, getParentList } from '../services/api';

const AcademyPage = ({academyTab}) => {
  const { user, /*loading*/ } = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const [menuTeamOptions, setMenuTeamOptions] = useState([]);
  const [menuParentOptions, setMenuParentOptions] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teams = await getTeamsList();
        const groups = await getParentList();
        setMenuTeamOptions(teams);
        setMenuParentOptions(groups);
      } catch (error) {
        console.error('Failed to fetch teams', error);
      }
    };

    fetchTeams();
  }, []);

  const updateTableData = () => {
    console.log("updateTableData");
    //fetchHistoryData();
  };


  return (
    <>
      <TextField
        fullWidth
        label="Global search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="small"
        className={'global-search'}
        variant="filled"
      />
      {academyTab === "exam_domains" && 
          <ExamDomains
          updateData={updateTableData}
          currentUser={user.username}
          userTeam={user.team}
          userGroup={user.usergroup}
          searchValue={searchValue}
          menuTeamOptions={menuTeamOptions}
          menuParentOptions={menuParentOptions}
        />
      }
      {academyTab === "all_domains" && 
          <AllDomains
          updateData={updateTableData}
          currentUser={user.username}
          userTeam={user.team}
          userGroup={user.usergroup}
          searchValue={searchValue}
          menuTeamOptions={menuTeamOptions}
          menuParentOptions={menuParentOptions}
        />
      }
      {academyTab === "tasks" && 
          <Tasks
          userGroup={user.usergroup}
          />
      }
      {academyTab === "ht" && 
          <HT/>
      }
    </>
  );
};

export default AcademyPage;