import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import DefRoute from './components/DefRoute';
import { useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { fetchProjectSettings, getParentsGodzilla } from './services/api';
import { setTeamsList, setGroupsList, setParentsList, setTabNames, setTabActivation, academySetEsp, setAcademyMailingType, setAcademyFooter, setAcademyBroadcastDays } from './reducers/SettingsSlice';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import ManagerPage from './pages/ManagerPage';
import MarketerPage from './pages/MarketerPage';
import SettingsPage from './pages/SettingsPage';
import AcademyPage from './pages/AcademyPage';
import { useDispatch } from 'react-redux';
import useWebSocketHook from './hooks/useWebSocketHook';

const RedirectLogic = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      if (user) {
        if (location.pathname === '/academy' || location.pathname === '/settings') {
          return;
        }
        switch (user.usergroup) {
          case 'admin':
            if (location.pathname !== '/admin') navigate('/admin');
            break;
          case 'manager':
            if (location.pathname !== '/manager') navigate('/manager');
            break;
          case 'marketer':
            if (location.pathname !== '/marketer') navigate('/marketer');
            break;
          case 'intern':
            if (location.pathname !== '/academy') navigate('/academy');
            break;
          default:
            if (location.pathname !== '/') navigate('/');
        }
      } else {
        if (location.pathname !== '/login') navigate('/login');
      }
    }
  }, [user, loading, navigate, location]);

  return null;
};

function App() {
  const [currentPage, setCurrentPage] = useState("MasterSheet");
  const [academyTab, setAcademyTab] = useState("exam_domains");
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await fetchProjectSettings();
        const parents = await getParentsGodzilla();
        dispatch(setTeamsList(settings.global?.teams_list || []));
        dispatch(setGroupsList(settings.global?.groups_list || []));
        const combinedParentsList = [
          ...(settings.global?.parents_list || []),
          ...parents
        ];
        dispatch(setParentsList(combinedParentsList));
        dispatch(setTabNames(settings.academy?.tab_name || []));
        dispatch(setTabActivation(settings.academy?.tab_activation || Array(settings.academy?.tab_name.length).fill(false)));
        dispatch(academySetEsp(settings.academy?.esp || ''));
        dispatch(setAcademyMailingType(settings.academy?.mailing_type || ''));
        dispatch(setAcademyFooter(settings.academy?.footer || ''));
        dispatch(setAcademyBroadcastDays(settings.academy?.broadcast_days || ''));
      } catch (error) {
        console.error('Failed to load settings:', error);
      }
    };

    loadSettings();
  }, [dispatch]);

    useWebSocketHook(user);
  

  return (
      <Router>
        <Header currentPage={currentPage} setCurrentPage={setCurrentPage} academyTab={academyTab} setAcademyTab={setAcademyTab} />
        <RedirectLogic />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route path="wrdprs" element={<DefRoute />} />
            <Route path="admin" element={<AdminPage currentPage={currentPage} />} />
            <Route path="manager" element={<ManagerPage />} />
            <Route path="marketer" element={<MarketerPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="academy" element={<AcademyPage academyTab={academyTab} />} />
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
