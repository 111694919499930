import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, InputLabel, FormControl, CircularProgress, Paper, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { fetchUsersData } from '../../actions/UsersAcademyActions';
import { checkHomeWork } from '../../services/api';

export default function LinkCheck() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersAcademyData.usersAcademyData);
  const loading = useSelector(state => state.usersAcademyData.loading);
  const error = useSelector(state => state.usersAcademyData.error);
  const settings = useSelector(state => state.settings);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [textInput, setTextInput] = useState('');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleTabChange = (event) => {
    setSelectedTabIndex(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleCheckHomeworkClick = async () => {
    const lines = textInput.split('\n').map(line => line.trim()).filter(line => line.length > 0);
    try {
      const results = await Promise.all(lines.map(async (url, index) => {
        const result = await checkHomeWork(url, selectedUser, selectedTabIndex, index);
        return result;
      }));

      const uniqueKeys = [...new Set(results.flatMap(result => Object.keys(result)))];

      const formattedData = results.map(result => {
        return uniqueKeys.map(key => ({
          value: result[key]?.value || '',
          verified: result[key]?.verified || false
        }));
      });

      setTableData(formattedData);
    } catch (error) {
      console.error('Error processing URLs:', error);
    }
  };

  if (loading) {
    return (
      <div style={{ height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  const columnNames = ['Index', 'Protocol', 'Tracking', 'ID', 'Merge Tag', 'Abb domain', 'Type', 'Copy'];

  function TableHeader() {
    return (
      <TableRow>
        {columnNames.map((name, index) => {
        // Определяем ширину для каждой колонки
        const columnWidth = (name === 'Index' || name === 'Protocol') ? 40 : 'auto';
        
        return (
          <TableCell
            key={index}
            variant="head"
            align="center"
            style={{
              width: columnWidth,
              backgroundColor: 'white',
              border: '1px solid #ddd',
            }}
          >
            {name}
          </TableCell>
        );
      })}
      </TableRow>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="user-select-label">Select User</InputLabel>
        <Select
          labelId="user-select-label"
          value={selectedUser}
          onChange={handleUserChange}
          label="Select User"
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.username}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <FormControl fullWidth margin="normal">
        <InputLabel id="tab-select-label">Select Tab</InputLabel>
        <Select
          labelId="tab-select-label"
          value={selectedTabIndex !== null ? selectedTabIndex : ''}
          onChange={handleTabChange}
          label="Select Tab"
        >
          {settings.academy.tabNames.map((tab, index) => (
            <MenuItem key={index} value={index}>
              {tab}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <TextField
        label="Enter Text"
        multiline
        rows={10}
        value={textInput}
        onChange={handleTextChange}
        variant="outlined"
        fullWidth
        margin="normal"
        sx={{ marginTop: '20px' }}
      />
      
      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckHomeworkClick}
        sx={{ marginTop: '20px' }}
      >
        Check Links
      </Button>

      {/* Вывод таблицы с данными */}
      {tableData.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Results Table:</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableHeader />
              </TableHead>
              <TableBody>
              {tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    // Проверка, если строка первая
                    const isFirstRow = cellIndex === 0;

                    // Вычисление отображаемого значения
                    const displayedValue = isFirstRow 
                      ? `${parseInt(cell.value, 10) + 1}` 
                      : cell.value;
                  
                    return (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: cell.verified ? 'lightgreen' : 'transparent'
                        }}
                      >
                        {displayedValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
