import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch, /* useSelector */ } from 'react-redux';
import { updateLocalTableData, addNewlocalTableRow, deleteByIdTableRowLocal } from '../actions/tableActions';
import { updateLocalAcademyTableData, /*addNewlocalTableRow*/ } from '../actions/tableAcademyActions';
import { fetchHistoryData } from '../actions/historyActions';

const socketUrl = `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_API_URL}`;

const useCustomWebSocket = (user) => {
  const dispatch = useDispatch();
  //const tableFilled = useSelector(state => state.table.filled);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    user ? socketUrl : null,
    {
      shouldReconnect: (closeEvent) => true,
      onOpen: () => console.log('WebSocket opened'),
      onClose: () => console.log('WebSocket closed'),
      onError: (event) => console.error('WebSocket error:', event),
    }
  );

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const messageData = JSON.parse(lastMessage.data);
      if (messageData.action === 'update-all-domains') {
        if (user) {
          //console.log('Received update-all-domains message from server, team: ', user.team);
          //console.log("New val: ", messageData.id, messageData.columnName, messageData.value);
          dispatch(fetchHistoryData(user.team));
            //console.log("updateLocalTableData");
            dispatch(updateLocalTableData(messageData.id, messageData.columnName, messageData.value));
        }
      }
      if (messageData.action === 'add-all-domains') {
        if (user) {
          console.log('Received add-all-domains message from server, team: ', user.team);
          console.log("New val: ", messageData.newRow);
          dispatch(fetchHistoryData(user.team));
            console.log("addNewlocalTableRow");
            dispatch(addNewlocalTableRow(messageData.newRow));
        }
      }
      if (messageData.action === 'delete-all-domains') {
        if (user) {
          console.log('Received delete-all-domains message from server, team: ', user.team);
          console.log("Deleted id: ", messageData.id);
          dispatch(fetchHistoryData(user.team));
            console.log("addNewlocalTableRow");
            dispatch(deleteByIdTableRowLocal(messageData.id));
        }
      }
      if (messageData.action === 'update-academy-exam') {
        if (user) {
          console.log('Received update-academy-exam message from server, team: ', user.team);
          console.log("New val: ", messageData.value);
          dispatch(fetchHistoryData(user.team));
            console.log("addNewlocalTableRow");
            dispatch(updateLocalAcademyTableData(messageData.id, messageData.columnName, messageData.value));
        }
      }
    }
  }, [lastMessage, dispatch, user]);

  return { sendMessage, lastMessage, readyState };
};

export default useCustomWebSocket;
