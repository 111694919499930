import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, InputLabel, FormControl, TextField, Button, CircularProgress, Box, Grid } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Импорт стилей tippy.js
import { fetchUsersData } from '../../actions/UsersAcademyActions';
import { getUserCopies, checkCopyHomeWork, getUserBroadcastDomains, getUserCopiesBroadcast } from '../../services/api';

export default function CopyCheck() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersAcademyData.usersAcademyData);
  const loading = useSelector(state => state.usersAcademyData.loading);
  const error = useSelector(state => state.usersAcademyData.error);
  const settings = useSelector(state => state.settings);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [textInput, setTextInput] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [copies, setCopies] = useState([]);
  const [domains, setDomains] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedDomainUsername, setSelectedDomainUsername] = useState('');
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const tooltipRefs = useRef({});

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  useEffect(() => {
    const getCopies = async (user) => {
      try {
        setSelectedCopy('');
        setCopies([]);
        const copies = await getUserCopies(user, selectedTabIndex);
        const copiesArray = copies ? copies.split('\n') : [];
        setCopies(copiesArray);
      } catch (error) {
        console.error('Error fetching copies:', error);
      }
    };
    const getDomains = async (user) => {
      try {
        setSelectedCopy('');
        setDomains([]);
        const domains = await getUserBroadcastDomains(user);
        setDomains(domains);
        console.log(domains);
        //setDomains(domainsArray);
      } catch (error) {
        console.error('Error fetching domains:', error);
      }
    };
    if (selectedUser) {
      getCopies(selectedUser);
    }
    if (selectedTabIndex === 5) {
      console.log(555);
      getDomains(selectedUser);
    } else{
      setDomains([]);
    }
  }, [selectedTabIndex, selectedUser]);

  useEffect(() => {
    const getCopies = async () => {
      try {
        setSelectedCopy('');
        setCopies([]);
        const copies = await getUserCopiesBroadcast(selectedDomainUsername, selectedTabIndex, selectedDomain);
        console.log('copies: ', copies);
        const copiesArray = copies ? copies.split('\n') : [];
        setCopies(copiesArray);
      } catch (error) {
        console.error('Error fetching copies:', error);
      }
    };
    if (selectedDomain) {
      console.log('selectedDomain ', selectedDomain);
      getCopies();
    }
  }, [selectedDomain, selectedDomainUsername, selectedTabIndex]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
    setCopies([]);
  };

  const handleTabChange = (event) => {
    setSelectedTabIndex(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleCopyChange = (copy) => {
    setSelectedCopy(copy);
  };
  const handleDomainChange = (domain, username) => {
    setSelectedDomain(domain);
    setSelectedDomainUsername(username);
  };

  const handleCheckCopyClick = async () => {
    let result;
    if (selectedTabIndex === 5) {
      result = await checkCopyHomeWork(textInput, selectedDomainUsername, selectedTabIndex, selectedCopy);
    } else{
      result = await checkCopyHomeWork(textInput, selectedUser, selectedTabIndex, selectedCopy);
    }
    
    setHtmlContent(result);
  };

  const handleMouseOver = (event) => {
    const target = event.target.closest('a');
    if (target) {
      const info = target.getAttribute('data-link-info');
      const id = target.getAttribute('id');
      if (info && id) {
        const parsedInfo = JSON.parse(info);
        setTooltipInfo(parsedInfo);
        tooltipRefs.current = target;
      }
    }
  };

  const handleMouseOut = () => {
    setTooltipInfo(null);
  };

  const getTooltipStyle = (verified) => {
    return {
      color: verified ? 'green' : 'red',
      fontWeight: verified ? 'bold' : 'normal',
    };
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setTextInput(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  function RenderDomain({ domain }) {
    return (
        domain.replace(/\.com$/, '')
    );
  }

  const isButtonDisabled = !selectedUser || selectedTabIndex === null || !selectedCopy || textInput.length===0;

  if (loading) {
    return (
      <div style={{ height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            onChange={handleUserChange}
            label="Select User"
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.username}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="tab-select-label">Select Tab</InputLabel>
          <Select
            labelId="tab-select-label"
            value={selectedTabIndex !== null ? selectedTabIndex : ''}
            onChange={handleTabChange}
            label="Select Tab"
          >
            {settings.academy.tabNames.map((tab, index) => (
              <MenuItem key={index} value={index}>
                {tab}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ marginTop: '20px' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
            {domains.map((domain, index) => (
              <Button
                key={index}
                variant={selectedDomain === domain.value ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleDomainChange(domain.value, domain.username)}
              >
                <RenderDomain domain={domain.value} />
              </Button>
            ))}
          </div>
        </Box>

        <Box sx={{ marginTop: '20px' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
            {copies.map((copy, index) => (
              <Button
                key={index}
                variant={selectedCopy === copy ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleCopyChange(copy)}
              >
                {copy}
              </Button>
            ))}
          </div>
        </Box>

        <TextField
          label="Enter Text"
          multiline
          rows={10}
          value={textInput}
          onChange={handleTextChange}
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{ marginTop: '20px' }}
        />

        <input
          accept=".html"
          style={{ display: 'none' }}
          id="upload-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="upload-file">
          <Button
            variant="outlined"
            color="primary"
            component="span"
            sx={{ marginTop: '20px', marginRight: '10px' }}
          >
            Upload HTML File
          </Button>
        </label>

        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: '20px' }}
          onClick={handleCheckCopyClick}
          disabled={isButtonDisabled}
        >
          Check copy
        </Button>
      </Grid>

      <Grid item xs={12} md={6}>
        {htmlContent && (
          <Box
            sx={{
              padding: '20px',
              borderRadius: '4px',
              border: '1px solid #ddd',
              backgroundColor: '#f9f9f9',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: htmlContent }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
            {tooltipInfo && (
              <Tippy
                content={
                  <div>
                    <div style={getTooltipStyle(tooltipInfo.Protocol.verified)}>
                      <strong>Protocol:</strong> {tooltipInfo.Protocol.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.Tracking.verified)}>
                      <strong>Tracking:</strong> {tooltipInfo.Tracking.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.ID.verified)}>
                      <strong>ID:</strong> {tooltipInfo.ID.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.MergeTag.verified)}>
                      <strong>MergeTag:</strong> {tooltipInfo.MergeTag.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.AbbDomain.verified)}>
                      <strong>AbbDomain:</strong> {tooltipInfo.AbbDomain.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.Type.verified)}>
                      <strong>Type:</strong> {tooltipInfo.Type.value}
                    </div>
                    <div style={getTooltipStyle(tooltipInfo.Copy.verified)}>
                      <strong>Copy:</strong> {tooltipInfo.Copy.value}
                    </div>
                    {tooltipInfo.imgUrl && (
                      <div style={getTooltipStyle(tooltipInfo.imgUrl.verified)}>
                        <strong>ImageHost:</strong> {tooltipInfo.imgUrl.value}
                      </div>
                    )}
                  </div>
                }
                visible={Boolean(tooltipInfo)}
                interactive={true}
                placement="top"
                arrow
                reference={tooltipRefs.current}
                animation="fade"
              />
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
